<template>
  <div class="emb-signIn-wrap section-gap mt-10">
    <div class="container py-0">
      <v-layout row wrap align-center justify-center>
        <v-flex sm12 md12 lg8 xl7>
          <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
            <v-flex sm10 md5 lg5 xl6>
              <div class="emb-card sign-in-form form-margin d-block white pa-6">
                <div style="text-align: center">
                  <img
                    alt="VB Brothers Pvt. Ltd."
                    :src="appLogo"
                    style="width: 250px; text-align: center"
                  />
                </div>
                <h4>Sign In</h4>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    outlined
                    v-model="form.contact_number"
                    type="mobile"
                    placeholder="Mobile No*"
                    :rules="[
                      rules.required,
                      rules.mobile,
                      rules.maldivesNumber,
                    ]"
                    hint="Mobile Number should be registered in Maldives (Dhiraagu or Ooredoo)."
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    v-model="form.password"
                    placeholder="Enter Password*"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.password]"
                    :type="show1 ? 'text' : 'password'"
                    label="Password"
                    hint="Min. 9 characters with at least one capital letter, a number and a special character."
                    counter
                    @click:append="show1 = !show1"
                  >
                  </v-text-field>
                  <div class="layout align-center justify-space-between pr">
                    <router-link to="/forgot-password" class="text-lg-right"
                      >Forgot Password ?</router-link
                    >
                  </div>
                  <v-btn class="accent mb-3 ma-0" @click.stop.prevent="login">
                    Sign In
                  </v-btn>
                </v-form>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import AppConfig from "Constants/AppConfig";
export default {
  data() {
    return {
      appLogo: AppConfig.appLogo,
      show1: false,
      show2: false,
      valid: false,
      checkbox: false,
      form: {
        contact_number: "",
        password: "",
        remember_me: false,
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 9 || "Min 9 characters",
        password: (value) => {
          return true;
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{9,})/;
          return (
            pattern.test(value) ||
            "Min. 9 characters with at least one capital letter, a number and a special character."
          );
        },
        password_confimation: (value) => {
          return (
            this.form.password === this.form.password_confirmation ||
            "Should be exactly the same as Password"
          );
        },
        mobile: (v) => {
          return v.length === 7 || "Should be 7 Numbers";
        },
        maldivesNumber: (v) => {
          return (
            (v > 7000000 && v < 8000000) ||
            (v > 9000000 && v < 9999999) ||
            "Should be a valid number from Dhiraagu or Ooredoo"
          );
        },
        email: (v) => {
          if (v.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
            return pattern.test(v) || "Should be a valid Email";
          } else {
            return true;
          }
        },
      },
    };
  },
  methods: {
    login() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.post("auth/login", this.form)
        .then((response) => {
          if (response.data.status == true) {
            this.$store.dispatch("setToken", response.data.token);
            this.$store.dispatch("setUser", response.data.user);
            this.$store.dispatch("setFcmToken", "");
            this.fcmRegister(response.data.user, true);
            this.$router.push("/home");
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    },
  },
};
</script>	
<style>
.v-input__icon {
  margin-top: 13px !important;
}
</style>